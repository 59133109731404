//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProfileDashboard',
  methods: {
    switchState(state) {
      this.$store.commit('profile/SwitchState',state)
    }
  },
  data() {
    return {
      tab: 'posts'
    }
  }
}
